import React from 'react'
import './branchList.css'
const BranchList = () => {
  return (
    <div>
      
    </div>
  )
}

export default BranchList

import React from 'react'
import './dashboard.css'
const Dashboard = () => {
  return (
    <div>
      dashboard
    </div>
  )
}

export default Dashboard
